import { AuthForm, IntroLayout } from 'components';
import { Message, Segment } from 'semantic-ui-react';
import React, { Fragment, useEffect, useState } from 'react';
import { checkAuthToken, getUser, sendLoginEmail } from '../utilities/auth';

import { isCustomer } from '../utilities/customers';
import { navigate } from 'gatsby';
import { useResume } from '../utilities/resumeHook';

const AuthLandingPage = ({ location: { state } }) => {
  const resume = useResume();
  const autoSend = state && state.autoSend;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState('');

  const sendEmail = async values => {
    setFormError('');
    setFormSubmitted(false);
    const { email } = values;
    const isValidCustomer = await isCustomer(email);
    if (isValidCustomer) {
      sendLoginEmail(email);
      setFormSubmitted(true);
    } else {
      setFormError(`No customer/license for ${email} found`);
    }
  };

  useEffect(() => {
    checkAuthToken(() => {
      navigate('/');
    });

    if (getUser()) {
      resume.run();
    }
  });

  useEffect(() => {
    if (autoSend) sendEmail({ email: state.email });
  }, []);

  return (
    <IntroLayout centered>
      <Segment className="auth-form">
        {!autoSend && (
          <Fragment>
            <h1>Sign In to GrowFlow</h1>
            <AuthForm onSubmit={sendEmail} />
          </Fragment>
        )}
        {formSubmitted && (
          <Message positive>
            <Message.Header>
              {autoSend ? <h2>Hold Up!</h2> : 'Login Email Sent'}
            </Message.Header>
            <br />
            {autoSend ? (
              <div>
                <p>
                  Based on what we have in our system, it looks like your email address,{' '}
                  {state.email} has already been used to start a GrowFlow Account Setup.
                </p>
                <p>
                  <b>
                    We emailed {state.email} a one-time-use login link - check that email
                    address now to log back in and continue your GrowFlow Account Setup.
                  </b>
                </p>
                <p>
                  To protect your account, this link will expire in 2 hours. If you
                  received more than one email with a login link, ensure you are clicking
                  the link in the most recently sent email.
                </p>
                <p>
                  If you think this is an error, reach out to{' '}
                  <a href="mailto:activation@getgrowflow.com">
                    activation@getgrowflow.com
                  </a>{' '}
                  - we will get your issue sorted out immediately!
                </p>
                <p>Thanks!</p>
              </div>
            ) : (
              <div>
                <p>
                  An email has been sent to your email address provided. Please check your
                  email for a one time login link.
                </p>
                <p>
                  To protect your account, this link will expire in 2 hours. If you
                  received more than one email with a login link, ensure you are clicking
                  the link in the most recently sent email.
                </p>
                <p>
                  If you think there is an error, reach out to{' '}
                  <a href="mailto:activation@getgrowflow.com">
                    activation@getgrowflow.com
                  </a>{' '}
                  - we will get your issue sorted out immediately!
                </p>
              </div>
            )}
          </Message>
        )}
        {formError && (
          <Message negative>
            <Message.Header>Error </Message.Header>
            <p>{formError}</p>
          </Message>
        )}
      </Segment>
      <br />
    </IntroLayout>
  );
};

export default AuthLandingPage;
